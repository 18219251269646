import React, { useState } from "react";
import FormInput from "../form-input/form-input";
import emailjs from "emailjs-com";
import "./contactus-form.css";

const defaultFormFields = {
  name: "",
  email: "",
  phone: "",
  message: "",
  service: "", 
};

const ContactUsForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { name, email, phone, message, service } = formFields;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      phone: phone,
      message: message,
      service: service, 
      to_email: "info@absleb.com",
    };

    try {
      await emailjs.send(
        "Service-absleb2024",
        "template_rlpc94b",
        templateParams,
        "0mDq6eBEFglO4Z2Bf" 
      );
      alert("Message sent successfully!");
      resetFormFields();
    } catch (error) {
      console.error("Failed to send the message:", error);
      alert("Failed to send the message. Please try again later.");
    }
  };

  return (
    <div className="contact-us-container">
      <h2>GET IN TOUCH!</h2>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Name"
          type="text"
          required
          onChange={handleChange}
          name="name"
          value={name}
        />

        <FormInput
          label="Email"
          type="email"
          required
          onChange={handleChange}
          name="email"
          value={email}
        />

        <FormInput
          label="Phone number*"
          type="tel"
          onChange={handleChange}
          name="phone"
          value={phone}
        />
        <FormInput
          label="Choose Service"
          name="service"
          value={service}
          onChange={handleChange}
        />
        <FormInput
          label="Message"
          type="text"
          required
          onChange={handleChange}
          name="message"
          value={message}
        />

        <div className="buttons">
          <button className="button" type="submit">
            Leave a message!
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
