import React from "react";
import "./form-input.css";

const FormInput = ({ label, maxRows, ...otherProps }) => {
  return (
    <div className="group">
      {label && (
        <label
          className={`${
            otherProps.value && otherProps.value.length ? "shrink" : ""
          } form-input-label`}
        >
          {label}
        </label>
      )}
      {label === "Message" ? (
        <textarea className="form-input" rows={maxRows} {...otherProps} />
      ) : label === "Choose Service" ? (
        <select className="form-input" {...otherProps}>
          <option value=""></option>
          <option value="structural-integrity">
            Structural Integrity (Analysis and Design)
          </option>
          <option value="structural-assessment">Structural Assessment</option>
          <option value="rc-pt-designs">RC/PT Designs</option>
          <option value="structural-steel-design">
            Structural Steel Design
          </option>

          <option value="conceptual-designs">Conceptual Designs</option>
          <option value="3d-visualization">
            3D Visualization and Rendering
          </option>
          <option value="space-planning">Space Planning</option>
          <option value="landscape-design">Landscape Design</option>

          <option value="digital-twins">
            Digital Twins/Virtual Construction
          </option>
          <option value="lod-400">Applying LOD 400</option>

          <option value="shop-drawings-rc">
            Shop Drawings for RC Structures
          </option>
          <option value="shop-drawings-steel">
            Shop Drawings for Steel Structures
          </option>
          <option value="boq-material-takeoff">
            BOQ and Material Takeoff Reports
          </option>
          <option value="cost-estimation">Cost Estimation</option>

          <option value="construction-planning">
            Construction Planning & Project Scheduling
          </option>
          <option value="construction-support">
            Construction Support and Site Supervision
          </option>
        </select>
      ) : (
        <input className="form-input" {...otherProps} />
      )}
    </div>
  );
};

export default FormInput;
