import React, { useState } from 'react';
import './service-card.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faSave, faTimes, faArrowRight, faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from '../../../contexts/auth.context';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ServiceCard = ({ id, title, description, image, icons, onDelete, onSave }) => {
  const { currentUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedDescription, setEditedDescription] = useState(description);

  const handleSave = () => {
    onSave(id, { title: editedTitle, description: editedDescription });
    setIsEditing(false);
  };
  const handleClick = () => {
    document.getElementById("contactus").scrollIntoView({ behavior: "smooth" });
  };

  const isDescriptionEmpty = description.length === 0;

  return (
    <>
      <div className="service-card">
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faScrewdriverWrench} style={{ color: "#263870" }} />
          {currentUser && (
            <div className="edit-icons">
              {isEditing ? (
                <>
                  <FontAwesomeIcon icon={faSave} onClick={handleSave} />
                  <FontAwesomeIcon icon={faTimes} onClick={() => setIsEditing(false)} />
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faEdit} onClick={() => setIsEditing(true)} />
                  <FontAwesomeIcon icon={faTrash} onClick={() => onDelete(id)} />
                </>
              )}
            </div>
          )}
        </div>
        {isEditing ? (
          <div className="edit-form">
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
            />
            <textarea
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
            />
          </div>
        ) : (
          <>
            <h3>{title}</h3>
            <div className="card-content">
              {!isDescriptionEmpty ? (
                <div className="card-image-description">
                  <div className="card-image-wrapper">
                    <img src={image} alt={title} className="card-image" />
                  </div>
                  <div className="card-description">
                    <ul className="description-list">
                      {description.map((item, index) => (
                        <li key={index} className="description-item">{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="card-image-only">
                  <LazyLoadImage src={image} alt={title} className="card-image" />
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className="request-service-card">
        <button className="request-service-button" onClick={handleClick}>
          Request a Service <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </>
  );
};

export default ServiceCard;
