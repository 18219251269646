import React from "react";
import About from "../../components/about/about";
import './home.css'
import Counter from "../../components/counter/counter";
import Services from "../../components/services/services";
import Projects from "../../components/projects/projects";
import ContactUs from "../../components/contactus/contactus";
import Footer from "../../components/footer/footer";
import Banner from "../../components/banner/banner";
// import UploadImage from "../../utils/firebase/sec";

const Home = () => {
  return(
    <div className="home-container">
      <div id="about">
        <About />
      </div>
      <div id="services">
        <Services />
      </div>
        <Counter />
      <div id="projects">
        <Projects />
      </div>
      <div>
        <Banner />
      </div>
      {/* <UploadImage /> */}
      <div id="contactus">
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
