import React, { useState } from "react";
import ServiceCard from "../service-card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleDown } from "@fortawesome/free-solid-svg-icons"; 
import "./subService.css"; 

const SubService = ({ subServiceTitle, services }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className="subservice-container">
      <div className="subservice-header" onClick={toggleVisibility}>
        <h2 className="subservice-title">{subServiceTitle}</h2>
        <div className="subservice-icon">
          <FontAwesomeIcon icon={isVisible ? faAngleDown : faAngleRight} />
        </div>
      </div>
      <div className={`subservice-content ${isVisible ? 'visible' : ''}`}>
        <div className={`row ${services.length <= 3 ? 'small' : 'large'}`}>
          {services.map((service, index) => (
            <div className="col" key={index}>
              <ServiceCard
                id={service.id}
                title={service.title}
                description={service.description}
                image={service.image}
                icons={service.icon}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubService;
