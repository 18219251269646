import React, { useState } from "react";
import PageTitle from "../page-title/page-title";
import SubService from "./service-card/subservice/subService";
import './services.css';
import { useServices } from "../../contexts/services.context";
import { useAuth } from "../../contexts/auth.context";

const Services = () => {
  const { services, loading, handleAddService } = useServices();
  const { currentUser } = useAuth();
  const [newTitle, setNewTitle] = useState("");
  const [newDescription, setNewDescription] = useState("");

  const handleAddServiceClick = async () => {
    const newService = {
      title: newTitle,
      description: newDescription,
      order: services.length + 1 
    };
    await handleAddService(newService);
    setNewTitle("");
    setNewDescription("");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="services-container">
      <PageTitle title="Our Services" />
      <section id="advertisers" className="advertisers-service-sec pt-5 pb-5">
        <div className="container">
          {services.map((service, index) => (
            <SubService
              key={service.id}
              subServiceTitle={service.title}
              services={service.items}
            />
          ))}
        </div>
      </section>
      {currentUser && (
        <div className="add-service-form">
          <input
            type="text"
            placeholder="New Service Title"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <textarea
            placeholder="New Service Description"
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
          <button onClick={handleAddServiceClick}>Add Service</button>
        </div>
      )}
    </div>
  );
};

export default Services;
