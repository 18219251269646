import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import PageTitle from "../page-title/page-title";
import './contactus.css';
import img1 from '../../assets/Group 14.png';
import ContactUsForm from "../contactus-form/contactus-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import LazyImage from "../lazyimage/lazyimage";

const mapContainerStyle = {
  width: '100%',
  height: '300px',
};

const center = {
  lat: 33.509618,
  lng: 35.830472,
};

const ContactUs = () => {
  return (
    <div className="contactus-container">
      <PageTitle title="Contact US" />
      <div className="contactus-container-details">
        <div className="contactus-info">
        <span>LET'S BUILD YOUR DREAM HOUSE!</span>
          <div className="contact-info-group">
            <div className="contact-info-location">
              <span>Lebanon</span>
              <div className="contact-info">
                <FontAwesomeIcon icon={faPhone} style={{ color: "var(--primary-blue)" }} />
                <a href="tel:+96178902338">+961 70 773 347</a> | 
                <a href="tel:+96170773347">+961 78 902 338</a>
              </div>
            </div>
            <div className="contact-info-location">
              <span>UAE</span>
              <div className="contact-info">
                <FontAwesomeIcon icon={faPhone} style={{ color: "var(--primary-blue)" }} />
                <a href="tel:+971501562474">+971 50 156 2474</a> | 
                <a href="tel:+971522668224">+971 52 266 8224</a>
              </div>
            </div>
          </div>
          <div className="contact-info">
            <FontAwesomeIcon icon={faEnvelope} style={{ color: "var(--primary-blue)" }} />
            <a href="mailto:info@absleb.com">info@absleb.com</a>
          </div>
          <div className="map-container">
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={15}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
        <div className="details-container">
          <div className="image-container">
            <LazyImage src={img1} alt="Construction Site" />
          </div>
          <div className="form-container">
            <ContactUsForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
