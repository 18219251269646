import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../page-title/page-title";
import "./projects.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { useProjects } from "../../contexts/projects.context";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Projects = () => {
  const { projects } = useProjects();

  return (
    <div className="projects-container">
      <div className="projects-title">
        <PageTitle title="Our Projects" />
      </div>
      <Splide
        className="projects-splide unique-projects-splide"
        options={{
          type: "loop",
          perPage: 3,
          focus: "center",
          pauseOnHover: true,
          autoplay: true,
          gap: "1rem",
          arrows: true,
          pagination: false,
          easing: "ease-in-out",
          speed: 1000,
          width: "95%",
          height:"100%",
          padding: 10,
          interval: 3000,
          updateOnMove: true,
          breakpoints: {
            868: {
              perPage: 1,
            },
          },
        }}
        aria-label="Project Images"
      >
        {projects.map((project, index) => (
          <SplideSlide key={index}>
            <div className="project-slide">
              <div className="project-image-wrapper">
                <LazyLoadImage
                  src={project.item.images[0]}
                  alt={`${project.item.name} ${index + 1}`}
                  className="project-image"
                />
                <div className="overlay">
                  <div className="text-content">
                    <h3>{project.item.name}</h3>
                    <p className="date">{project.item.date}</p>
                    <p className="description">{project.description}</p>
                    <Link to={`/project/${project.id}`} className="read-more">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default Projects;
