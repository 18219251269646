import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";
import oneyx from "../../assets/oneyx.png";
import alarez from "../../assets/alarez.png";
import "./businesses.css";

const Businesses = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/#home");
    setTimeout(() => {
      const homeSection = document.getElementById("/home");
      if (homeSection) {
        homeSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className="businesses-read">
      <button onClick={handleBack} className="back-link">
        <FontAwesomeIcon icon={faArrowLeft} /> Back
      </button>
      <div className="businesses-header">
        <h2>Our Businesses</h2>
      </div>
      <div className="cardbusiness-container">
        <div className="cardbusiness">
          <LazyLoadImage
            src={alarez}
            alt="Alarez"
            className="cardbusiness-image"
          />
          <div className="cardbusiness-text">
            <h3>AL AREZ TRADING LLC</h3>
            <h4>Dubai, Uae</h4>
            <p>
              With a specialization in marble, ceramic, and porcelain products,
              we are dedicated to providing top-notch materials for all your
              construction needs. Our expertise and commitment to excellence
              ensure that you receive only the best, whether for residential,
              commercial, or industrial projects. Partner with us and experience
              the difference that quality materials can make in bringing your
              construction visions to life.
            </p>
          </div>
          <a
            href="https://alareztrd.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="readmore-button"
          >
            Read More
          </a>
        </div>
        <div className="cardbusiness">
          <LazyLoadImage
            src={oneyx}
            alt="Oneyx"
            className="cardbusiness-image"
          />
          <div className="cardbusiness-text">
            <h3>Oneyx For technical Services Contracting</h3>
            <h4>Dubai, Uae</h4>
            <p>
              We are dedicated to blending traditional craftsmanship with modern
              innovation in every space we transform, prioritizing
              functionality, aesthetics, and sustainability. Our approach is
              client-focused, ensuring each project reflects excellence and
              efficiency, bringing visions to life with integrity and precision.
            </p>
          </div>
          <a
            href="https://oneyx.ae/index.html#"
            target="_blank"
            rel="noopener noreferrer"
            className="readmore-button"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

export default Businesses;
