// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { ServicesProvider } from './contexts/services.context';
import { AuthProvider } from './contexts/auth.context';
import { ProjectsProvider } from './contexts/projects.context';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <AuthProvider>
      <ProjectsProvider>
        <ServicesProvider>
        <App />
      </ServicesProvider>
      </ProjectsProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
