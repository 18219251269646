import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Navigation from './routes/navigation/navigation';
import Home from './routes/home/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProjectDetails from './components/project/project';
import SignInForm from './components/sign-in/sign-in';
// import SignUpForm from './components/sign-up/sign-up';
import PrivateRoute from './components/private-route/private-route';
import AboutusRead from './components/about/aboutus-read/aboutus-read';
import Businesses from './components/businesses/businesses';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route path='/' element={<Home />} />
        <Route path="/project/:id" element={<ProjectDetails />} />
        <Route path="/aboutus-read" element={<AboutusRead />} />
        <Route path="/businesses" element={<Businesses />} />
        <Route path='admin' element={<PrivateRoute element={Home} />} />
      </Route>
      {/* <Route path='/signup' element={<SignUpForm />} /> */}
      <Route path='/login' element={<SignInForm />} />
    </Routes>
  );
}

export default App;
