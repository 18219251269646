// import React, { createContext, useContext, useEffect, useState } from "react";
// import { collection, getDocs } from "firebase/firestore";
// import {firestore, addService, deleteService, updateService } from "../utils/firebase/firebase.utils";

// const ServicesContext = createContext();

// export const useServices = () => useContext(ServicesContext);

// export const ServicesProvider = ({ children }) => {
//   const [services, setServices] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchServices = async () => {
//       try {
//         const servicesCollection = collection(firestore, "services");
//         const servicesSnapshot = await getDocs(servicesCollection);
//         const servicesList = servicesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setServices(servicesList);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching services: ", error);
//       }
//     };

//     fetchServices();
//   }, []);

//   const handleAddService = async (newService) => {
//     const addedService = await addService(newService);
//     setServices([...services, addedService]);
//   };

//   const handleDeleteService = async (id) => {
//     await deleteService(id);
//     setServices(services.filter(service => service.id !== id));
//   };

//   const handleUpdateService = async (id, updatedService) => {
//     const updated = await updateService(id, updatedService);
//     setServices(services.map(service => service.id === id ? updated : service));
//   };

//   return (
//     <ServicesContext.Provider value={{ services, loading, handleAddService, handleDeleteService, handleUpdateService }}>
//       {children}
//     </ServicesContext.Provider>
//   );
// };

import React, { useState, useEffect, createContext, useContext } from "react";
import { fetchServices, addService, deleteService, updateService } from "../utils/firebase/firebase.utils"; // Adjust based on your file structure

const ServicesContext = createContext();

export const useServices = () => useContext(ServicesContext);

export const ServicesProvider = ({ children }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getServices = async () => {
      try {
        const servicesData = await fetchServices();
        const sortedServices = servicesData.sort((a, b) => a.order - b.order);
        setServices(sortedServices);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching services: ", error);
      }
    };

    getServices();
  }, []);

  const handleAddService = async (newService) => {
    const addedService = await addService(newService);
    setServices(prevServices => [...prevServices, addedService].sort((a, b) => a.order - b.order));
  };

  const handleDeleteService = async (id) => {
    await deleteService(id);
    setServices(prevServices => prevServices.filter(service => service.id !== id));
  };

  const handleUpdateService = async (id, updatedService) => {
    const updated = await updateService(id, updatedService);
    setServices(prevServices => {
      const updatedServices = prevServices.map(service => service.id === id ? updated : service);
      return updatedServices.sort((a, b) => a.order - b.order);
    });
  };

  return (
    <ServicesContext.Provider value={{ services, loading, handleAddService, handleDeleteService, handleUpdateService }}>
      {children}
    </ServicesContext.Provider>
  );
};
