import { useState } from "react";
import { signinAuthUserWithEmailAndPassword } from "../../utils/firebase/firebase.utils";
import { useNavigate } from "react-router";
import '../sign-up/sign-up.css';

const defaultFormFields = {
  email: "",
  password: "",
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await signinAuthUserWithEmailAndPassword(email, password);
      navigate('/admin');
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        alert(" wrong email or password");
      }
      console.error("user could not be created", error.message);
    }
    resetFormFields();
  };

  return (
    <div className="sign-up-container">
      <h2>Logn in</h2>
      <span>Sign up with your email and password</span>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            required
            onChange={handleChange}
            name="email"
            value={email}
            id="email"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            required
            onChange={handleChange}
            name="password"
            value={password}
            id="password"
          />
        </div>
        <button type="submit">Sign In</button>
      </form>
    </div>
  );
};

export default SignInForm;
