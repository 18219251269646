import React from "react";
import "./footer.css";
import abs from "../../assets/Layer 1-White.png";
import companyprofile from '../../assets/image.png';
import LazyImage from "../lazyimage/lazyimage";
import {
  faInstagram,
  faLinkedin,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-top">
        <h2>
          Our team of skilled professionals is always prepared and available,
          anytime and anywhere.
        </h2>
      </div>
      <div className="footer-bottom">
        <div className="footer-logo">
          <LazyImage src={abs} alt="Company Logo" />
          <Link to="https://issuu.com/oneyxtsc/docs/abs_company_profile-final">
            <LazyImage src={companyprofile} alt="Company Logo" />
          </Link>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <Link>
              <h3>LINKS</h3>
            </Link>
            <a href="#home">HOME</a>
            <a href="#about" to="about">ABOUT US</a>
            <a href="#services">SERVICES</a>
            <a href="#projects">PROJECTS</a>
            <a href="#contactus">CONTACT US</a>
          </div>
          <div className="footer-column">
            <Link to="/businesses">
              <h3>OUR BUSINESSES</h3>
            </Link>
            <a
              href="https://alareztrd.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              AL AREZ
            </a>
            <a
              href="https://oneyx.ae/index.html#"
              target="_blank"
              rel="noopener noreferrer"
            >
              ONEYX
            </a>
          </div>
          <div className="footer-column">
            <Link>
            <h3>SOCIAL LINKS</h3>
            </Link>
            <div className="social-links">
              <a
                href="https://www.instagram.com/abs_leb?igsh=cHI2dDhtOGRrcGZ2&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/company/advanced-building-services-lebanon/about/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a
                href="https://www.facebook.com/share/X1mkdy3LPuYPZ494/?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a
                href="https://wa.me/+96170773347"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-text">
        <p>&copy; 2024, All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
