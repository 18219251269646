import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase/firebase.utils";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faLocationDot, faBarsStaggered, faExpand, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./project.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProjectDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/#projects");
    setTimeout(() => {
      const projectsSection = document.getElementById("projects");
      if (projectsSection) {
        projectsSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const projectDoc = doc(db, "projects", id);
        const projectSnapshot = await getDoc(projectDoc);
        if (projectSnapshot.exists()) {
          setProject(projectSnapshot.data().item);
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    fetchProject();
  }, [id]);

  if (!project) {
    return <div></div>;
  }

  return (
    <div className="project-details-container">
      <div className="project-title-back-link">
        <button className="back-link" onClick={handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>
        <div className="project-titlee">
        <h2>{project.name}</h2>
        </div>
      </div>
      <div className="project-content">
        <div className="slideshow-container">
          <Splide
            options={{
              type: "loop",
              perPage: 1,
              autoplay: true,
              pauseOnHover: true,
              interval: 3000,
              arrows: true,
              pagination: true,
              easing: "ease-in-out",
              speed: 1000,
            }}
          >
            {project.images.map((image, index) => (
              <SplideSlide key={index}>
                <div className="project-image-wrapper">
                  <LazyLoadImage
                    src={image}
                    alt={`${project.name} ${index + 1}`}
                    className="project-image"
                  />
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
        <div className="project-info">
          <div className="project-details">
            <div className="project-details-row">
              <p>
                <FontAwesomeIcon icon={faLocationDot} style={{ color: "#263870" }} className="fa-icon" /> {project.location}
              </p>
              <p>
                <FontAwesomeIcon icon={faExpand} style={{ color: "#263870" }} className="fa-icon" /> {project.area[0]}
              </p>
              <p>
                <FontAwesomeIcon icon={faBarsStaggered} style={{ color: "#263870" }} className="fa-icon" /> {project.duration}
              </p>
            </div>
            <h2>Scope</h2>
            <ul>
              {project.scope.map((service, index) => (
                <li key={index}>
                  <FontAwesomeIcon icon={faGears} style={{ color: "#263870" }} className="fa-icon" /> {service}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
