import React, { useEffect, useRef } from 'react';
import './about.css';
import Image2 from '../../assets/about1.jpg';
import PageTitle from '../page-title/page-title';
import LazyImage from '../lazyimage/lazyimage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench, faHouse, faPersonDigging } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const About = () => {
  const imageRef = useRef(null);

  useEffect(() => {
    const currentImageRef = imageRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate');
          } else {
            entry.target.classList.remove('animate');
          }
        });
      },
      { threshold: 0.4 }
    );

    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, []);

  return (
    <div className='about-container' id="about">
      <PageTitle title='About Us' />
      <div className='about-content'>
        <div className='about-image-container' ref={imageRef}>
          <div className='background-design'></div>
          <LazyImage src={Image2} alt='About us' className='animated-image' />
        </div>
        <div className='about-details-container'>
          <h2>We build for your comfort</h2>
          <span className='about-span'>Advanced Building Services, is a dynamic contracting and engineering firm specializing in a comprehensive range of construction services.</span>
          <div>
            <ul className='about-list'>
              <li className='about-list-item'>
                <FontAwesomeIcon icon={faScrewdriverWrench} className='about-icon' /> Expertise in executing specially designed buildings
              </li>
              <li className='about-list-item'>
                <FontAwesomeIcon icon={faHouse} className='about-icon' /> Commitment to excellence and innovation
              </li>
              <li className='about-list-item'>
                <FontAwesomeIcon icon={faPersonDigging} className='about-icon' /> Consistently delivering projects that meet the highest standards.
              </li>
            </ul>
            <Link to='/aboutus-read' className='about-button'>Read More</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
