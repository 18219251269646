import React, { useEffect, useRef, useState } from 'react';
import './banner.css';

const Banner = () => {
  const bannerRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const handleClick = () => {
    document.getElementById("contactus").scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    const currentBannerRef = bannerRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
          } else {
            setIsInView(false);
          }
        });
      },
      { threshold: 0.4 }
    );

    if (currentBannerRef) {
      observer.observe(currentBannerRef);
    }

    return () => {
      if (currentBannerRef) {
        observer.unobserve(currentBannerRef);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      const texts = document.querySelectorAll('.animated-text');
      texts.forEach((text) => {
        const letters = text.innerText.split('');
        text.innerHTML = letters.map((letter, i) => `<span class="letter" style="animation-delay:${i * 0.1}s">${letter}</span>`).join('');
      });
    }
  }, [isInView]);

  return (
    <div className={`banner-container ${isInView ? 'fade-in' : ''}`} ref={bannerRef}>
      <div className="text-section">
        <p className="animated-text">Perfection</p>
      </div>
      <div className="text-section">
        <p className="animated-text">Precision</p>
      </div>
      <div className="text-section">
        <p className="animated-text">Performance</p>
      </div>
      <button className="contact-button" onClick={handleClick}>Contact Us</button>
    </div>
  );
};

export default Banner;
