import React, { useEffect, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, Outlet, useLocation } from "react-router-dom";
import ABSLogo from "../../assets/Layer 1.png";
import IMG1 from "../../assets/img1.JPG";
import IMG2 from "../../assets/img2.JPG";
import IMG3 from "../../assets/img3.JPG";
import IMG4 from "../../assets/img4.JPG";
import IMG5 from "../../assets/img5.JPG";
import IMG6 from "../../assets/IMG6.JPG";
import IMG7 from "../../assets/IMG7.JPG";

import "./navigation.styles.css";
import { useAuth } from "../../contexts/auth.context";
import { signoutUser } from "../../utils/firebase/firebase.utils";
import {  scroller } from "react-scroll";
import { LazyLoadImage } from "react-lazy-load-image-component";

const slideImages = [
  {
    url: IMG1,
    caption: "YOUR PROJECT STARTS WITH US",
  },
  {
    url: IMG2,
    caption: "BUILD YOUR DREAMS",
  },
  {
    url: IMG3,
    caption: "ENGINEERING INNOVATION AT ITS BEST",
  },
  {
    url: IMG4,
    caption: "FOUNDATION FOR THE FUTURE",
  },
  {
    url: IMG5,
    caption: "CREATING ENDURING STRUCTURES",
  },
  {
    url: IMG6,
    caption: "CRAFTING TOMORROW'S LANDSCAPE",
  },
  {
    url: IMG7,
    caption: "PRECISION IN EVERY DETAIL",
  }
];

const splitCaption = (caption) => {
  const words = caption.split(" ");
  const midpoint = Math.ceil(words.length / 2);
  const firstHalf = words.slice(0, midpoint).join(" ");
  const secondHalf = words.slice(midpoint).join(" ");
  return [firstHalf, secondHalf];
};

function NavigationWithSlideshow() {
  const location = useLocation();
  const { currentUser } = useAuth();
  const [currentCaption, setCurrentCaption] = useState(
    splitCaption(slideImages[0].caption)
  );
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsPopupVisible(false);
      setTimeout(() => {
        setIsPopupVisible(true);
      }, 1000);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleMove = (splide) => {
    const newIndex = splide.index;
    setCurrentCaption(splitCaption(slideImages[newIndex].caption));
    setCurrentIndex(newIndex);
  };

  const isProjectDetailsPage =
    location.pathname.startsWith("/project") ||
    location.pathname.startsWith("/aboutus-read") ||
    location.pathname.startsWith("/businesses");

  const handleSignOut = async () => {
    await signoutUser(currentUser);
  };

  const handleLinkClick = (target) => {
    scroller.scrollTo(target, {
      smooth: true,
      duration: 1000,
    });
    setShowOffcanvas(false);
  };

  return (
    <>
      {!isProjectDetailsPage && (
        <div className="fullscreen-container" id="home">
          {["xl"].map((expand) => (
            <Navbar
              key={expand}
              expand={expand}
              className="navigation-container" 
              fluid
            >
              <div className="logo-container">
                <Link to="/">
                  <img src={ABSLogo} alt="ABS Logo" />
                </Link>
              </div>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                onClick={() => setShowOffcanvas(true)}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                show={showOffcanvas}
                onHide={() => setShowOffcanvas(false)}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Menu
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link
                      className={showOffcanvas ? "blue-link" : ""}
                      onClick={() => handleLinkClick("about")}
                    >
                      ABOUT US
                    </Nav.Link>
                    <Nav.Link
                      className={showOffcanvas ? "blue-link" : ""}
                      onClick={() => handleLinkClick("services")}
                    >
                      SERVICES
                    </Nav.Link>
                    <Nav.Link
                      className={showOffcanvas ? "blue-link" : ""}
                      onClick={() => handleLinkClick("projects")}
                    >
                      PROJECTS
                    </Nav.Link>
                    <Link
                      to="businesses"
                      className={`nav-link ${showOffcanvas ? "blue-link" : ""}`}
                      onClick={() => setShowOffcanvas(false)}
                    >
                      OUR BUSINESSES
                    </Link>
                    <Nav.Link
                      className={showOffcanvas ? "blue-link" : ""}
                      onClick={() => handleLinkClick("contactus")}
                    >
                      CONTACT US
                    </Nav.Link>
                    {currentUser && (
                      <Link
                        to="/"
                        className={`nav-link ${
                          showOffcanvas ? "blue-link" : ""
                        }`}
                        onClick={() => {
                          handleSignOut();
                          setShowOffcanvas(false);
                        }}
                      >
                        LOG OUT
                      </Link>
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          ))}
          <Splide
            className="navigation-splide"
            options={{
              type: "loop",
              autoplay: true,
              interval: 3000,
              pauseOnHover: false,
              resetProgress: false,
              arrows: false,
              pagination: false,
              height: "100vh",
              speed: 1000,
              easing: "ease-in-out",
              breakpoints: {
                768: {
                  perPage: 1,
                },
              },
            }}
            onMove={handleMove}
          >
            {slideImages.map((slideImage, index) => (
              <SplideSlide key={index}>
                <div className="navigation-image-container">
                  <LazyLoadImage
                    src={slideImage.url}
                    alt={`Slide ${index + 1}`}
                    className="navigation-image"
                  />
                  {isPopupVisible && (
                    <div className="navigation-title-overlay">
                      {currentCaption.map((line, index) => (
                        <div
                          key={index}
                          className={`navigation-line ${
                            index === 0 ? "slide-down" : "slide-up"
                          }`}
                        >
                          {line}
                        </div>
                      ))}
                      <Link className="learn-more-btn" to="/aboutus-read">
                        Read More
                      </Link>
                    </div>
                  )}
                </div>
              </SplideSlide>
            ))}
          </Splide>
          <div className="navigation-pagination">
            {slideImages.map((_, index) => (
              <div
                key={index}
                className={`navigation-item ${
                  index === currentIndex ? "is-active" : ""
                }`}
                onClick={() => handleMove({ index })}
              />
            ))}
          </div>
        </div>
      )}
      <div className="content">
        <Outlet />
      </div>
    </>
  );
}

export default NavigationWithSlideshow;
