import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './aboutus-read.css';
import aboutUsImage from '../../../assets/Layer 1.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const AboutusRead = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/#about");
    setTimeout(() => {
      const aboutSection = document.getElementById("about");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="aboutus-read">
      <button onClick={handleBack} className="back-link">
        <FontAwesomeIcon icon={faArrowLeft} /> Back
      </button>
      <div className="aboutus-header">
        <img src={aboutUsImage} alt="About Us" className="aboutus-image" />
      </div>
      <div className="card-container">
        <div className="card mission">
          <h2>Mission</h2>
          <div className="card-text">
            <p>
              Our mission is to provide high-quality, innovative engineering solutions that exceed our clients' expectations. We strive to foster a collaborative and safe working environment, delivering projects on time and within budget.
            </p>
          </div>
        </div>
        <div className="card vision">
          <h2>Vision</h2>
          <div className="card-text">
            <p>
              Our vision is to be a leader in the civil engineering industry, known for our excellence and innovation. We aim to shape the future of infrastructure, creating solutions that are not only effective but also environmentally responsible.
            </p>
          </div>
        </div>
        <div className="card value">
          <h2>Value</h2>
          <div className="card-text">
            <p>
              We believe in the power of teamwork, continuous learning, and ethical practices. Our commitment to these values is reflected in our work and our relationships with clients, partners, and the community.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutusRead;
