import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import './counter.css';

const data = [
  { end: 58, label: 'Projects', postfix: '+'},
  { end: 175, label: 'Men Hours',  postfix: 'K+' },
  { end: 2500, label: 'Budget', prefix: '$', postfix: 'K+' },
  { end: 30, label: 'Clients' ,  postfix: '+'},
];

const Counter = () => {
  const { ref, inView } = useInView({
    threshold: 0.4, 
  }); 
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (inView) {
      setActive(false);
      setTimeout(() => setActive(true), 100); // Small delay to reset counter
    }
  }, [inView]);

  return (
    <div className={`counter-container ${inView ? 'slide-in' : ''}`} ref={ref}>
      <h2 className="counter-title">We Take Pride in Our Numbers</h2>
      <div className="counter-items">
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <div className="counter-item">
              <div className="counter-value">
                {active && <CountUp end={item.end} duration={3} prefix={item.prefix} />}
                <span className="postfix">{item.postfix}</span>
              </div>
              <p>{item.label}</p>
            </div>
            {index < data.length - 1 && <div className="vertical-line"></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Counter;
