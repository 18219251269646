import React from "react";
import './page-title.css';

const PageTitle = ({title}) => {
    return(
        <div className='title-container'>
            <div className="orange-line"></div>
            <div className="title"><span>{title}</span></div>
        </div>
    );
};

export default PageTitle;
